import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import Headline from '@objects/headline'
import Stage from '@components/stage'
import Container from '@objects/container'
import TeaserCardList from '@components/teaserCardList'

function Events({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()
  const eventUrlPart = 'veranstaltungen'

  const stageEvent = ((hero) => {
    return hero && hero.stageImage
      ? {
          stageImage: hero.stageImage,
          headline: hero.title,
          copy: hero.copy?.childMarkdownRemark?.html,
          date: hero.dateTimeStart || hero.createdAt,
          dateEnd: hero.dateTimeEnd,
          location: hero.shortLocation,
          link: `/${eventUrlPart}/${hero.slug}`,
        }
      : null
  })(get(data, 'contentfulEventList.eventHero'))

  const events = get(data, 'contentfulEventList.events')
    ?.sort((a, b) => differenceInDays(b.dateTimeStart, a.dateTimeStart))
    .map((item) => ({
      id: item.id,
      type: 'event',
      image: item.teaserImage?.fluid,
      stageImage: item.stageImage,
      headline: item.title,
      copy: item.copy?.childMarkdownRemark?.html,
      duration: {
        start: item.dateTimeStart,
        end: item.dateTimeEnd,
      },
      location: item.shortLocation,
      link: item.slug,
    }))

  return (
    <>
      <Container nopadding role="region" aria-label="Events">
        <Headline
          className="my-8 md:my-9"
          ariaLabel={intl.formatMessage({ id: 'events.headline' })}
        >
          {intl.formatMessage({ id: 'events.headline' })}
        </Headline>
      </Container>
      {stageEvent && (
        <Stage
          className="mb-11 md:mb-13"
          type="infocard"
          image={{ ...stageEvent.stageImage, desktopAspectRatio: 2.38 }}
          infocard={{
            truncate: 25,
            headline: stageEvent.headline,
            copy: stageEvent.copy,
            date: stageEvent.date,
            dateEnd: stageEvent.dateEnd,
            location: stageEvent.location,
            button: {
              to: stageEvent.link,
              label: intl.formatMessage({ id: 'button.eventdetail' }),
            },
          }}
        />
      )}

      <Container nopadding className="mb-11 md:mb-13">
        {events && (
          <TeaserCardList
            className={'lg:w-8/12 lg:mx-auto'}
            teaserList={events}
            separator
          />
        )}
      </Container>
    </>
  )
}

Events.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Events

export const pageQuery = graphql`
  query EventsPageQuery($locale: String!) {
    contentfulEventList(
      node_locale: { eq: $locale }
      identifier: { eq: "events" }
    ) {
      eventHero {
        dateTimeEnd
        dateTimeStart
        location
        shortLocation
        title
        stageImage {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        copy {
          childMarkdownRemark {
            html
          }
        }
        slug
      }
      events {
        title
        slug
        dateTimeStart
        dateTimeEnd
        copy {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        shortLocation
      }
    }
  }
`
